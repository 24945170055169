import clsx from 'clsx';
import React from 'react';

import { EQuoteCardBackground } from './QuoteCard.constants';
import QuoteCardWithAvatar from './QuoteCardWithAvatar';
import { ImageWithPlaceholder } from '../../atoms/ImageWithPlaceholder';
import { RichText } from '../../atoms/RichText';
import { Container } from '../../sections/Container';

import type { TQuoteCardProps } from './types';

const QuoteCard: React.FC<TQuoteCardProps> = props => {
  const { authorTitle, banner, authorPosition, description, theme } = props;

  let cardContentStyles = 'bg-brand-500 text-interface-50';
  let authorPositionStyles = 'text-interface-50/80';

  if (theme === EQuoteCardBackground.BlockquoteWithAvatar) {
    return <QuoteCardWithAvatar {...props} />;
  }

  if (theme === EQuoteCardBackground.Theme1) {
    cardContentStyles = 'bg-surface-100 text-interface-1000';
    authorPositionStyles = 'text-interface-500';
  } else if (theme === EQuoteCardBackground.Theme2) {
    cardContentStyles = 'bg-surface-50 text-interface-1000';
    authorPositionStyles = 'text-interface-500';
  } else if (theme === EQuoteCardBackground.Theme3) {
    cardContentStyles = 'bg-brand-500 text-interface-50';
    authorPositionStyles = 'text-interface-50/80';
  } else if (theme === EQuoteCardBackground.Theme4) {
    cardContentStyles = 'bg-accent-500 text-brand-1000';
    authorPositionStyles = 'text-interface-1000/80';
  } else if (theme === EQuoteCardBackground.Theme5) {
    cardContentStyles = 'bg-surface-800 text-interface-50';
    authorPositionStyles = 'text-interface-50/80';
  } else if (theme === EQuoteCardBackground.Theme6) {
    cardContentStyles = 'bg-surface-950 text-interface-50';
    authorPositionStyles = 'text-interface-50/80';
  }

  return (
    <Container>
      <div className='flex flex-col gap-4 lg:flex-row'>
        {banner && (
          <div className='relative shrink-0 overflow-hidden rounded-3xl md:hidden xl:block xl:h-[14.5rem] xl:w-full xl:max-w-[22.5rem] 2xl:max-w-[25.5rem]'>
            <ImageWithPlaceholder
              {...banner}
              className={clsx(
                '!relative size-full object-contain object-bottom xl:object-cover',
              )}
            />
          </div>
        )}
        <div
          className={clsx(
            'flex w-full flex-col rounded-3xl p-8 2xl:self-stretch',
            cardContentStyles,
          )}
        >
          {description && (
            <div className='text-xl font-semibold leading-normal'>
              <RichText content={description} />
            </div>
          )}
          <div className='mt-6 flex flex-col gap-1 lg:mt-[1.875rem]'>
            {authorTitle && (
              <span className='text-base leading-extra-loose'>
                {authorTitle}
              </span>
            )}
            {authorPosition && (
              <span
                className={clsx(
                  'text-xs font-medium leading-relaxed',
                  authorPositionStyles,
                )}
              >
                {authorPosition}
              </span>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default QuoteCard;
