import React from 'react';

import toImageProps from '../../../utils/toImageProps';
import { RichText } from '../../atoms/RichText';
import { ImageWithPlaceholder } from '../../atoms/ImageWithPlaceholder';

import type { TQuoteCardProps } from './types';

export const CardWithQuoteV2: React.FC<TQuoteCardProps> = ({
  authorTitle,
  authorPosition,
  description,
  authorAvatar,
}) => {
  const avatarProps = toImageProps(authorAvatar);

  return (
    <div className='mx-auto flex max-w-[816px] flex-col-reverse gap-6 py-8 tablet:flex-row desktop:gap-8'>
      <div className='flex w-60 flex-row gap-4'>
        {avatarProps ? (
          <div className='size-20 shrink-0 overflow-hidden'>
            <ImageWithPlaceholder
              {...avatarProps}
              itemProp='image'
              width={80}
              height={80}
              className='size-full rounded-full object-cover object-center'
            />
          </div>
        ) : null}

        <div className='flex flex-col gap-2'>
          {authorTitle ? (
            <div className='text-xl font-semibold leading-extra-loose text-control-950 [overflow-wrap:anywhere]'>
              {authorTitle}
            </div>
          ) : null}

          {authorPosition ? (
            <div className='text-base font-normal leading-[24.8px] tracking-[0.32px] text-interface-700 [overflow-wrap:anywhere]'>
              {authorPosition}
            </div>
          ) : null}
        </div>
      </div>

      <div className='flex flex-1 flex-col tablet:flex-row tablet:gap-4'>
        <div className='text-7xl font-semibold leading-snug text-control-600'>
          “
        </div>
        <div className='text-4xl font-semibold leading-relaxed text-control-950'>
          <RichText content={description} />
        </div>
      </div>
    </div>
  );
};
